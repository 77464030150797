import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import urlParam from 'plugins/utilities/url_param';

import LoadingDurationLogger from '../loading_duration_logger';
import HlsLiveV2 from './services/hls_live_v2';
import HlsV3 from './services/hls_v3';
import HlsVodV1 from './services/hls_vod_v1';
import Mp4 from './services/mp4';
import ShakaPlayer from './services/shaka_player';
import Vimeo from './services/vimeo';
import Wistia from './services/wistia';
import Youtube from './services/youtube';

function yodaVideoSources(videoId, videoSourcesData) {
  return ((videoSourcesData && videoSourcesData.videoCdnServers) || [])
    .flatMap((host) => [
      { key: `${host}/hls`, name: host, format: 'hls', url: `https://${host}/${videoId}/master.m3u8` },
      { key: `${host}/dash`, name: host, format: 'dash', url: `https://${host}/${videoId}/master.mpd` },
    ])
    .filter((source) => source.format === 'hls');
}

function urlVideoSources(videoId) {
  const urls = [videoId];
  return urls.map((url) => ({ key: url, name: url, format: 'mp4', url }));
}

function wowzaVideoSources(servers, streamKeyParam) {
  return servers.map((server) => {
    const url =
      streamKeyParam === 'slides_stream_key'
        ? server.server_info.abr_slides_playback_url
        : server.server_info.abr_playback_url;

    return { key: url, name: server.server_info.human_name, format: 'hls', url };
  });
}

export default class VideoPlayer {
  constructor(element, options) {
    this.loadingDurationLogger = new LoadingDurationLogger('VIDEO', {
      event: (event) => this.runCallbacks('loadingEvent', 'loadingEvent', event),
    });

    const initialPropValues = options.initial || {};

    this.element = element;
    this.options = options;

    this.callbacks = {
      ratioChanged: new Set(),
      ready: new Set(),
      loadingChanged: new Set(),
      loadingEvent: new Set(),
      stateChanged: new Set(),
      timesChanged: new Set(),
      seekableChanged: new Set(),
      seekRequest: new Set(),
      seeking: new Set(),
      seeked: new Set(),
      volumeChanged: new Set(),
      playFailed: new Set(),
      ended: new Set(),
      programDateTimeChanged: new Set(),
      timeUpdate: new Set(),

      playbackRateChanged: new Set(), // deprecated
      activePlaybackRateIndexChanged: new Set(),
      availablePlaybackRatesChanged: new Set(),

      qualityChanged: new Set(), // deprecated
      activeQualityIndexChanged: new Set(),
      availableQualitiesChanged: new Set(),

      activeSubtitlesChanged: new Set(), // deprecated
      activeSubtitlesIndexChanged: new Set(),
      subtitlesChanged: new Set(), // deprecated
      availableSubtitlesChanged: new Set(),
      renderSubtitlesWord: new Set(),

      playbackServerChanged: new Set(), // deprecated
      activeVideoSourceIndexChanged: new Set(),
      availableServersChanged: new Set(), // deprecated
      availableVideoSourcesChanged: new Set(),

      livestreamStartChanged: new Set(), // deprecated
      liveStreamStartedChanged: new Set(),
      inLivePositionChanged: new Set(),
      streamStartChanged: new Set(),
      timeSinceStreamStartChanged: new Set(),

      showError: new Set(),
      reportError: new Set(),
      debugInfo: new Set(),
      cmcd: new Set(),

      firstPlayHackChanged: new Set(),
    };

    this.props = {
      videoServiceName: '',

      volume: initialPropValues.volume || 100,
      muted: initialPropValues.muted || false,
      ratio: initialPropValues.ratio || 16 / 9.0,
      playbackRate: 1,
      quality: null,
      subtitlesEnabled: true,
      subtitlesTrack: null,
      subtitlesLanguage: initialPropValues.subtitlesLanguage,
      liveSubtitlesDelayMs: 0,
      liveFinished: false,

      loadingStart: null,

      size: null,

      lastCallbackQuality: null,
      lastCallbackPlaybackServer: null,
    };

    this.setVideoElementId();

    if (this._useHlsV3Service) {
      console.log('Using HLSv3 video service.');
    }
  }

  on(event, callback) {
    if (!this.callbacks[event]) {
      console.warn(`Unknown listener in VideoPlayer.on: ${event}`);
      return;
    }

    this.callbacks[event].add(callback);
  }

  runCallbacks(event, deprecatedEvent, ...args) {
    if (deprecatedEvent && this.options.callbacks && this.options.callbacks[deprecatedEvent]) {
      this.options.callbacks[deprecatedEvent](...args);
    }

    if (this.callbacks[event]) {
      for (const callback of this.callbacks[event]) {
        callback(...args);
      }
    }
  }

  setVideoElementId() {
    const randomString = Math.random().toString(36).substring(2, 7);
    const id = `slp-${randomString}`;

    this.element.setAttribute('id', id);
  }

  serviceOptions({ videoServiceName = null, videoId = null } = {}) {
    return {
      videoServiceName,
      videoId,
      presentationId: this.options.presentationId,
      presentationMediaSetId: this.options.presentationMediaSetId,
      analyticsUserUuid: this.options.analyticsUserUuid,
      analyticsSessionUuid: this.options.analyticsSessionUuid,
      embed: this.options.embed,
      accountId: this.options.accountId,
      contentType: this.options.contentType,
      contentTypeDetails: this.options.contentTypeDetails,
      source: this.options.source,
      live: this.options.live,
      autoPlay: this.options.autoPlay,
      defaultStreamQuality: this.options.defaultStreamQuality,
      originalVideoControls: this.options.originalVideoControls,
      initial: this.props,
      callbacks: {
        ready: () => {
          this.runCallbacks('ready', 'ready');
        },
        loadingChanged: (loading) => {
          this.loadingDurationLogger.logLoadingChange(loading);
          this.runCallbacks('loadingChanged', 'loadingChanged', loading);
        },
        stateChanged: (state) => {
          this.runCallbacks('stateChanged', 'stateChanged', state);
        },
        timesChanged: (currentTime, duration) => {
          this.runCallbacks('timesChanged', 'timesChanged', currentTime, duration);
        },
        ended: () => {
          this.runCallbacks('ended', 'ended');
        },
        showError: (error) => {
          this.runCallbacks('showError', 'showError', error);
        },
        playFailed: () => {
          this.runCallbacks('playFailed', 'playFailed');
        },

        volumeChanged: (volume, muted) => {
          this.props.volume = volume;
          this.props.muted = muted;
          this.runCallbacks('volumeChanged', 'volumeChanged', volume, muted);
        },
        playbackRateChanged: (playbackRate) => {
          this.props.playbackRate = playbackRate;
          this.runCallbacks('playbackRateChanged', 'playbackRateChanged', playbackRate);
        },
        qualityChanged: (quality) => {
          if (this.props.lastCallbackQuality !== quality) {
            this.props.lastCallbackQuality = quality;

            this.runCallbacks('qualityChanged', 'qualityChanged', quality);
          }
        },
        activeSubtitlesChanged: (track) => {
          this.runCallbacks('activeSubtitlesChanged', 'activeSubtitlesChanged', track);
        },
        ratioChanged: (ratio) => {
          this.props.ratio = ratio;
          this.runCallbacks('ratioChanged', 'ratioChanged', ratio);
        },

        availablePlaybackRatesChanged: (playbackRates) => {
          this.runCallbacks('availablePlaybackRatesChanged', 'availablePlaybackRatesChanged', playbackRates);
        },
        availableQualitiesChanged: (qualities) => {
          this.runCallbacks('availableQualitiesChanged', 'availableQualitiesChanged', qualities);
        },
        availableServersChanged: (servers) => {
          this.runCallbacks('availableServersChanged', 'availableServersChanged', servers);
        },
        subtitlesChanged: (tracks) => {
          this.runCallbacks('subtitlesChanged', 'subtitlesChanged', tracks);
        },

        playbackServerChanged: (server) => {
          if (this.props.lastCallbackPlaybackServer !== server) {
            this.props.lastCallbackPlaybackServer = server;

            this.runCallbacks('playbackServerChanged', 'playbackServerChanged', server);
          }
        },
        inLivePositionChanged: (inLivePosition) => {
          this.runCallbacks('inLivePositionChanged', 'inLivePositionChanged', inLivePosition);
        },
        streamStartChanged: (streamStart) => {
          this.runCallbacks('streamStartChanged', 'streamStartChanged', streamStart);
        },
        timeSinceStreamStartChanged: (timeSinceStreamStart) => {
          this.runCallbacks('timeSinceStreamStartChanged', 'timeSinceStreamStartChanged', timeSinceStreamStart);
        },
        programDateTimeChanged: (time) => {
          this.runCallbacks('programDateTimeChanged', 'programDateTimeChanged', time);
        },
        livestreamStartChanged: (started) => {
          this.runCallbacks('livestreamStartChanged', 'livestreamStartChanged', started);
        },

        firstPlayHackChanged: (firstPlayHack) => {
          this.runCallbacks('firstPlayHackChanged', 'firstPlayHackChanged', firstPlayHack);
        },

        seeking: () => this.runCallbacks('seeking', 'seeking'),
        seeked: () => this.runCallbacks('seeked', 'seeked'),
        seekRequest: (before, after) => this.runCallbacks('seekRequest', 'seekRequest', before, after),
        seekableChanged: (seekable) => this.runCallbacks('seekableChanged', 'seekableChanged', seekable),

        timeUpdate: (currentTime, duration) => this.runCallbacks('timeUpdate', 'timeUpdate', currentTime, duration),

        debugInfo: (info) => this.runCallbacks('debugInfo', 'debugInfo', info),
        reportError: (component, error, data) =>
          this.runCallbacks('reportError', 'reportError', component, error, data),
        cmcd: (...args) => this.runCallbacks('cmcd', 'cmcd', ...args),
      },
    };
  }

  _loadHlsV3Service(service, serviceData) {
    this.service = new HlsV3(this.element, this._hlsV3ServiceOptions());
    this._addHlsV3Listeners();

    let videoId;
    let videoSources = [];
    if (service === 'yoda') {
      videoId = serviceData.videoId;
      videoSources = yodaVideoSources(serviceData.videoId, serviceData.data);
    } else if (service === 'url') {
      videoSources = urlVideoSources(serviceData.videoId);
    } else if (service === 'live') {
      videoSources = wowzaVideoSources(serviceData.servers, serviceData.streamKeyParam);
    } else {
      this.runCallbacks('showError', 'showError', `Unknown service for HLSv3: ${service}`);
      return;
    }

    this.service.load(videoSources, { videoId });
  }

  load(service, videoId, videoServiceData) {
    if ((service === 'yoda' || service === 'url') && this._useHlsV3Service) {
      this._loadHlsV3Service(service, { videoId, data: videoServiceData });
      return;
    }

    const serviceOptions = this.serviceOptions({ videoServiceName: service, videoId });

    let serviceName = service;
    if (service.startsWith('yoda:')) {
      serviceName = service.split(':')[0];
    }

    switch (serviceName) {
      case 'youtube':
        this.service = new Youtube(this.element, serviceOptions);
        break;
      case 'vimeo':
        this.service = new Vimeo(this.element, serviceOptions);
        break;
      case 'wistia':
        this.service = new Wistia(this.element, serviceOptions);
        break;
      case 'url':
        this.service = new Mp4(this.element, serviceOptions);
        break;
      case 'shaka':
      case 'yoda':
        if (urlParam('use_hls_vod_v1_video_service')) {
          this.service = new HlsVodV1(this.element, serviceOptions);
        } else {
          this.service = new ShakaPlayer(this.element, serviceOptions);
        }
        break;
      default:
        this.runCallbacks('showError', 'showError', 'Unsupported video source.');
        return;
    }

    this.props.videoServiceName = service;
    this.service.load(service, videoId, videoServiceData);
  }

  loadLive(servers, streamKeyParam) {
    let serverType = null;

    if (servers.length === 0) {
      serverType = 'wowza';
    } else {
      for (const server of servers) {
        if (!isVariableDefinedNotNull(serverType)) {
          serverType = server.server_type;
        } else if (serverType !== server.server_type) {
          this.runCallbacks('showError', 'showError', 'Mixed live server types.');
          return;
        }
      }
    }

    if (serverType === 'wowza' && this._useHlsV3Service) {
      this._loadHlsV3Service('live', { servers, streamKeyParam });
      return;
    }

    switch (serverType) {
      case 'youtube':
        this.service = new Youtube(this.element, this.serviceOptions());
        break;
      case 'wowza':
        this.service = new HlsLiveV2(this.element, this.serviceOptions());
        break;
      default:
        this.runCallbacks('showError', 'showError', 'Unsupported live stream video source.');
        return;
    }

    this.props.videoServiceName = serverType;
    this.service.loadLive(servers, streamKeyParam);
  }

  loadOffline(video) {
    this.service = new Mp4(this.element, this.serviceOptions());
    this.service.loadOffline(video);
  }

  loadSubtitles(subtitles) {
    if (this.service.loadSubtitles) {
      this.service.loadSubtitles(subtitles);
    }
  }

  updateLiveServers(servers, streamKeyParam = 'stream_key') {
    if (this.service) {
      if (this._useHlsV3Service) {
        this.service.setVideoSources(wowzaVideoSources(servers, streamKeyParam));
      } else {
        this.service.updateServers(servers, streamKeyParam);
      }
    } else {
      this.loadLive(servers, streamKeyParam);
    }
  }

  togglePlayback() {
    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }

  toggleMute() {
    if (this.muted) {
      this.unmute();
    } else {
      this.mute();
    }
  }

  play() {
    if (this.service) {
      this.service.play();
    }
  }

  pause() {
    if (this.service) {
      this.service.pause();
    }
  }

  mute() {
    this.muted = true;
  }

  unmute() {
    this.muted = false;
  }

  seekToLivePosition() {
    if (this.service) {
      this.service.seekToLivePosition();
    }
  }

  setQuality(quality) {
    this.quality = quality;
  }

  setSubtitleTrack(track) {
    this.subtitlesTrack = track;
  }

  destroy() {
    if (this.service && this.service.destroy) {
      this.service.destroy();
    }
  }

  // Getters

  get videoElement() {
    if (this.service && this.service.videoElement) return this.service.videoElement;
    if (this.service && this.service.video) return this.service.video;

    return undefined;
  }

  get videoServiceName() {
    if (!this._useHlsV3Service) return this.props.videoServiceName;

    return 'hls.js';
  }

  get loading() {
    return this.service ? this.service.loading : true;
  }

  get ready() {
    return this.service ? this.service.ready : false;
  }

  get muted() {
    if (this._useHlsV3Service) {
      return this.service ? this.service.muted : false;
    }

    return this.props.muted;
  }

  set muted(muted) {
    if (!this._useHlsV3Service) this.props.muted = muted;

    if (this.service && this.service.setMuted) {
      this.service.setMuted(muted);
    } else if (this.service) {
      this.service.muted = muted;
    }
  }

  get volume() {
    return this.service ? this.service.volume : this.props.volume;
  }

  set volume(volume) {
    if (!this._useHlsV3Service) {
      this.props.volume = volume;
      this.props.muted = false;
    }

    if (this.service) {
      this.service.volume = volume;
    }
  }

  get programDateTime() {
    return this.service ? this.service.programDateTime : undefined;
  }

  get currentTime() {
    return this.service ? this.service.currentTime : 0;
  }

  set currentTime(value) {
    if (this.service) {
      this.service.currentTime = value;
    }
  }

  get currentStreamTime() {
    if (this.service && typeof this.service.currentStreamTime !== 'undefined') {
      return this.service.currentStreamTime;
    }

    return null;
  }

  get duration() {
    return this.service ? this.service.duration : 0;
  }

  set realPlaybackRate(rate) {
    if (!this._useHlsV3Service) this.props.playbackRate = parseFloat(rate);

    if (this._useHlsV3Service) {
      this.service.realPlaybackRate = parseFloat(rate);
    } else if (this.service) {
      this.service.playbackRate = parseFloat(rate);
    }
  }

  get realPlaybackRate() {
    return this._useHlsV3Service ? this.service.realPlaybackRate || 1 : this.props.playbackRate;
  }

  get playbackRate() {
    return this._useHlsV3Service ? this.service.activePlaybackRate?.playbackRate || 1 : this.props.playbackRate;
  }

  set playbackRate(rate) {
    if (!this._useHlsV3Service) this.props.playbackRate = parseFloat(rate);

    if (this._useHlsV3Service) {
      this.service.activePlaybackRateIndex = rate;
    } else if (this.service) {
      this.service.playbackRate = parseFloat(rate);
    }
  }

  get seekable() {
    return this._useHlsV3Service ? this.service.seekable : this.props.seekable;
  }

  get inLivePosition() {
    return this.service ? this.service.inLivePosition : false;
  }

  get live() {
    return this.options.live;
  }

  get state() {
    return this.service ? this.service.state : '';
  }

  get isPlaying() {
    return this.state === 'playing';
  }

  get isPaused() {
    return this.state === 'paused';
  }

  get ratio() {
    return this.props.ratio;
  }

  get quality() {
    return this._useHlsV3Service ? this.service.activeQualityIndex : this.props.quality;
  }

  set quality(quality) {
    if (!this._useHlsV3Service) this.props.quality = quality;

    if (this._useHlsV3Service) {
      this.service.activeQualityIndex = quality;
    } else if (this.service) {
      this.service.setQuality(quality);
    }
  }

  get subtitlesTrack() {
    return this._useHlsV3Service ? this.service.activeSubtitlesIndex : this.props.subtitlesTrack;
  }

  set subtitlesTrack(track) {
    if (!this._useHlsV3Service) this.props.subtitlesTrack = parseInt(track, 10);

    if (this._useHlsV3Service) {
      this.service.activeSubtitlesIndex = track;
    } else if (this.service) {
      this.service.setSubtitleTrack(parseInt(track, 10));
    }
  }

  get playbackServerIndex() {
    if (this._useHlsV3Service) {
      return this.service.activeVideoSourceIndex;
    }

    return this.service ? this.service.playbackServerIndex : null;
  }

  set playbackServer(serverIndex) {
    if (this._useHlsV3Service) {
      this.service.activeVideoSourceIndex = serverIndex;
    } else if (this.service) {
      this.service.playbackServer = serverIndex;
    }
  }

  get activeVideoSourceUrl() {
    return this.service && this.service.activeVideoSourceUrl ? this.service.activeVideoSourceUrl : null;
  }

  get activeQualityName() {
    return this.service && this.service.activeQualityName ? this.service.activeQualityName : null;
  }

  get activePlaybackRateValue() {
    return this.service && this.service.activePlaybackRateValue ? this.service.activePlaybackRateValue : null;
  }

  // Setters

  set subtitlesEnabled(enabled) {
    if (!this._useHlsV3Service) this.props.subtitlesEnabled = enabled;

    if (this._useHlsV3Service) {
      this.service.liveSubtitlesEnabled = enabled;
    } else if (this.service) {
      this.service.subtitlesEnabled = enabled;
    }
  }

  set liveSubtitlesDelayMs(delayMs) {
    if (!this._useHlsV3Service) this.props.liveSubtitlesDelayMs = delayMs;

    if (this.service) {
      this.service.liveSubtitlesDelayMs = delayMs;
    }
  }

  set liveFinished(finished) {
    if (!this._useHlsV3Service) this.props.liveFinished = finished;

    if (this.service) {
      this.service.liveFinished = finished;
    }
  }

  //

  set size(size) {
    if (!this._useHlsV3Service) this.props.size = size;

    if (this.service) {
      this.service.size = size;
    }
  }

  get useHlsJs() {
    return !!(this.service && this.service.useHlsJs);
  }

  // options

  _addHlsV3Listeners() {
    this.service.on('ratioChanged', (ratio) => this.runCallbacks('ratioChanged', 'ratioChanged', ratio));
    this.service.on('ready', () => this.runCallbacks('ready', 'ready'));
    this.service.on('loadingChanged', (loading) => {
      this.loadingDurationLogger.logLoadingChange(loading);
      this.runCallbacks('loadingChanged', 'loadingChanged', loading);
    });
    this.service.on('stateChanged', (state) => this.runCallbacks('stateChanged', 'stateChanged', state));
    this.service.on('timesChanged', (currentTime, duration) =>
      this.runCallbacks('timesChanged', 'timesChanged', currentTime, duration),
    );
    this.service.on('seekableChanged', (seekable) => this.runCallbacks('seekableChanged', 'seekableChanged', seekable));
    this.service.on('seekRequest', (before, after) => this.runCallbacks('seekRequest', before, after));
    this.service.on('seeking', () => this.runCallbacks('seeking', 'seeking'));
    this.service.on('seeked', () => this.runCallbacks('seeked', 'seeked'));
    this.service.on('volumeChanged', (volume, muted) =>
      this.runCallbacks('volumeChanged', 'volumeChanged', volume, muted),
    );
    this.service.on('playFailed', () => this.runCallbacks('playFailed', 'playFailed'));
    this.service.on('ended', () => this.runCallbacks('ended', 'ended'));
    this.service.on('programDateTimeChanged', (programDateTime) =>
      this.runCallbacks('programDateTimeChanged', 'programDateTimeChanged', programDateTime),
    );
    this.service.on('timeUpdate', (currentTime, duration) =>
      this.runCallbacks('timeUpdate', 'timeUpdate', currentTime, duration),
    );
    this.service.on('activePlaybackRateIndexChanged', (activePlaybackRateIndex) =>
      this.runCallbacks('playbackRateChanged', 'playbackRateChanged', activePlaybackRateIndex),
    );
    this.service.on('availablePlaybackRatesChanged', (playbackRates) =>
      this.runCallbacks('availablePlaybackRatesChanged', 'availablePlaybackRatesChanged', playbackRates),
    );
    this.service.on('activeQualityIndexChanged', (activeQualityIndex) =>
      this.runCallbacks('qualityChanged', 'qualityChanged', activeQualityIndex),
    );
    this.service.on('availableQualitiesChanged', (qualities) =>
      this.runCallbacks('availableQualitiesChanged', 'availableQualitiesChanged', qualities),
    );
    this.service.on('activeSubtitlesIndexChanged', (activeSubtitlesIndex) =>
      this.runCallbacks('activeSubtitlesChanged', 'activeSubtitlesChanged', activeSubtitlesIndex),
    );
    this.service.on('availableSubtitlesChanged', (subtitles) =>
      this.runCallbacks('subtitlesChanged', 'subtitlesChanged', subtitles),
    );
    this.service.on('renderSubtitlesWord', (subtitles) =>
      this.runCallbacks('renderSubtitlesWord', 'renderSubtitlesWord', subtitles),
    );
    this.service.on('activeVideoSourceIndexChanged', (activeVideoSourceIndex) =>
      this.runCallbacks('playbackServerChanged', 'playbackServerChanged', activeVideoSourceIndex),
    );
    this.service.on('availableVideoSourcesChanged', (videoSources) =>
      this.runCallbacks('availableServersChanged', 'availableServersChanged', videoSources),
    );
    this.service.on('liveStreamStartedChanged', (started) =>
      this.runCallbacks('livestreamStartChanged', 'livestreamStartChanged', started),
    );
    this.service.on('inLivePositionChanged', (inLivePosition) =>
      this.runCallbacks('inLivePositionChanged', 'inLivePositionChanged', inLivePosition),
    );
    this.service.on('streamStartChanged', (streamStart) =>
      this.runCallbacks('streamStartChanged', 'streamStartChanged', streamStart),
    );
    this.service.on('timeSinceStreamStartChanged', (timeSinceStreamStart) =>
      this.runCallbacks('timeSinceStreamStartChanged', 'timeSinceStreamStartChanged', timeSinceStreamStart),
    );
    this.service.on('showError', (error) => this.runCallbacks('showError', 'showError', error));
    this.service.on('reportError', (component, error, data) =>
      this.runCallbacks('reportError', 'reportError', component, error, data),
    );
    this.service.on('debugInfo', (debugInfo) => this.runCallbacks('debugInfo', 'debugInfo', debugInfo));
  }

  get _useHlsV3Service() {
    return (gon && gon.use_hls_v3_video_service) || !!urlParam('use_hls_v3_video_service');
  }

  _hlsV3ServiceOptions() {
    return {
      locale: this.options.locale,
      accountId: this.options.accountId,
      presentationId: this.options.presentationId,
      presentationMediaSetId: this.options.presentationMediaSetId,
      analyticsUserUuid: this.options.analyticsUserUuid,
      analyticsSessionUuid: this.options.analyticsSessionUuid,
      embed: this.options.embed,
      contentType: this.options.contentType,
      contentTypeDetails: this.options.contentTypeDetails,
      source: this.options.source,
      autoPlay: this.options.autoPlay,
      defaultStreamQuality: this.options.defaultStreamQuality,
      originalVideoControls: this.options.originalVideoControls,
      initial: this.props,

      live: this.options.live,
    };
  }
}

// eslint-disable-next-line max-classes-per-file
import isBotUserAgent from 'plugins/utilities/is_bot_user_agent';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function playlistTagToPropertyName(tag) {
  const key = tag
    .replace(/^EXT-SL-/, '')
    .split('-')
    .reduce((acc, cur) => `${acc}${cur.charAt(0).toUpperCase() + cur.slice(1).toLowerCase()}`, '');

  return key[0].toLowerCase() + key.slice(1);
}

function iso8601ToMs(time) {
  if (!isVariableDefinedNotNull(time) || time === '') {
    return null;
  }

  const parts = time.match(/\d+/g);
  return Date.UTC(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
}

function contactSupportMessage() {
  return 'If this error persists report it to support@slideslive.com.';
}

function botErrorMessage() {
  return [
    // eslint-disable-next-line max-len
    'It appears you are a search engine bot.<br>If not, please report this at <a class="link--white link--underline" href="mailto:support@slideslive.com">support@slideslive.com</a>.',
    '',
    `<span class="text__paragraph--tiniest">User-Agent: ${navigator.userAgent}</span>`,
  ].join('<br>');
}

function unknownErrorMessage() {
  return [
    'Player loading failed. Try again.',
    contactSupportMessage(),
    '',
    `<span class="text__paragraph--tiniest">User-Agent: ${navigator.userAgent}</span>`,
  ].join('<br>');
}

function errorCodeToMessage(errorCode) {
  let errorMessage;

  if (errorCode === 'expired_player_token') {
    errorMessage = 'Player session has expired.<br>Reload the page to watch the presentation.';
  } else if (errorCode === 'invalid_player_token_presentation_id_mismatch') {
    errorMessage = 'Player token is not valid.<br>Reload the page to watch the presentation.';
  } else if (
    errorCode === 'invalid_player_token_media_set_id_mismatch' ||
    errorCode === 'invalid_player_token_presentation_is_not_live_stream'
  ) {
    errorMessage =
      'Presentation media changed since you loaded the page.<br>Reload the page to watch the presentation.';
  } else {
    errorMessage = 'Player loading failed. Try again.';
  }

  return [
    errorMessage,
    contactSupportMessage(),
    '',
    `<span class="text__paragraph--tiniest">Error code: ${errorCode}</span>`,
    `<span class="text__paragraph--tiniest">User-Agent: ${navigator.userAgent}</span>`,
  ].join('<br>');
}

class Playlist {
  constructor() {
    Object.assign(this, {
      version: null,
      playlistType: null,
      playlistCreatedAt: null,

      customCmcdTracking: null,

      accountId: null,

      presentationId: null,
      presentationTitle: null,
      presentationUpdatedAt: null,
      presentationThumbnail: null,
      presentationMediaSetId: null,

      playingAnalyticsDisabled: null,
      startTime: null,
      videoAspectRatio: null,
      videoRatio: null,
      displayVideoRatio: null,
      defaultSubtitlesLanguage: null,
      playerLogo: null,

      nextPresentationId: null,
      nextPresentationTitle: null,
      nextPresentationThumbnail: null,

      playerType: null,

      hasVideo: null,
      hasAudio: null,
      hasSlides: null,

      vodVideoServiceName: null,
      vodVideoId: null,
      vodVideoServers: null,
      vodVideoKenEnabled: null,
      vodSlidesXmlUrl: null,
      vodSlidesJsonUrl: null,
      vodSubtitles: null,

      streamUpdatedAt: null,
      streamSlidesUpdatedAt: null,

      streamFinished: null,
      streamAvailableOnDemand: null,
      streamTerminated: null,

      streamVideoServers: null,
      streamVideoActualStart: null,
      streamVideoScheduledStart: null,
      streamSyncDelay: null,
      streamSubtitlesDelay: null,
      streamShowSlides: null,
      streamSubtitlesEnabled: null,
      streamUpdateInterval: null,
      streamReloadCounter: null,
      streamSlidesVideo: null,

      slidesliveLogoVisible: false,
      slidesliveLogoLinkify: false,

      streamSlides: [],
    });
  }

  _storePlaylistValue(tag, value) {
    const key = playlistTagToPropertyName(tag);

    // eslint-disable-next-line no-prototype-builtins
    if (!this.hasOwnProperty(key)) {
      console.warn(`No property for tag ${tag} (${key}):`, value);
      return;
    }

    this[key] = value;
  }

  _storePlaylistIntegerValue(tag, value) {
    this._storePlaylistValue(tag, parseInt(value, 10));
  }

  _storePlaylistFloatValue(tag, value) {
    this._storePlaylistValue(tag, parseFloat(value));
  }

  _storePlaylistStringValue(tag, value) {
    this._storePlaylistValue(tag, value.toString());
  }

  _storePlaylistDateTimeValue(tag, value) {
    if (isVariableDefinedNotNull(value) && value !== '') {
      const parts = value.match(/\d+/g);
      const ms = Date.UTC(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
      this._storePlaylistValue(tag, ms);
    }
  }

  _storePlaylistBooleanValue(tag, value) {
    this._storePlaylistValue(tag, value === true || value.toLowerCase() === 'true' || value.toLowerCase() === 'yes');
  }

  _storePlaylistJsonValue(tag, value) {
    this._storePlaylistValue(tag, JSON.parse(value));
  }

  _storePlaylistStreamSlide(tag, values, nextLine) {
    let time;
    let streamStart;
    let version;
    let bucket;
    let name;
    let originalPath;
    const url = nextLine;

    let pairStart = 0;
    let pairEnd;
    let pair;

    while (pairStart < values.length) {
      pairEnd = values.indexOf(',', pairStart);
      if (pairEnd < 0) pairEnd = values.length;

      pair = values.substring(pairStart, pairEnd);

      const equalsSignIndex = pair.indexOf('=');
      const key = pair.substring(0, equalsSignIndex);
      const value = pair.substring(equalsSignIndex + 1);

      switch (key) {
        case 'TIME':
          time = parseInt(value, 10);
          break;
        case 'STREAM-START':
          streamStart = parseInt(value, 10);
          break;
        case 'VERSION':
          version = parseInt(value, 10);
          break;
        case 'BUCKET':
          bucket = value;
          break;
        case 'NAME':
          name = value;
          break;
        case 'ORIGINAL-PATH':
          originalPath = value;
          break;
        default:
          console.warn(`Unhandled stream slide value key ${key}:`, value);
      }

      pairStart = pairEnd + 1;
    }

    this.streamSlides.push({
      time,
      streamStart,
      url,
      version,
      bucket,
      name,
      originalPath,
    });
  }

  toDeprecatedPresentationInfo() {
    const presentationUpdatedAtInMs = iso8601ToMs(this.presentationUpdatedAt);
    const updatedAt = Math.round(presentationUpdatedAtInMs / 1000);

    const streamSlides = this.streamSlides;

    return {
      custom_cmcd_tracking: this.customCmcdTracking,
      account_id: this.accountId,
      title: this.presentationTitle,
      is_live: this.playerType === 'live',
      player_type: this.playerType,
      has_video: this.hasVideo,
      has_audio: this.hasAudio,
      has_slides: this.hasSlides,
      video_service_name: this.vodVideoServiceName,
      video_service_id: this.vodVideoId,
      video_cdn_servers: this.vodVideoServers,
      video_ken_enabled: this.vodVideoKenEnabled,
      subtitles: this.vodSubtitles,
      slides_xml_url: this.vodSlidesXmlUrl,
      slides_json_url: this.vodSlidesJsonUrl,
      default_subtitles_language: this.defaultSubtitlesLanguage,
      next_presentation: {
        id: this.nextPresentationId,
        title: this.nextPresentationTitle,
        thumbnail: this.nextPresentationThumbnail,
      },
      playing_analytics_disabled: this.playingAnalyticsDisabled,
      thumbnail: this.presentationThumbnail,
      player_start_time: this.startTime,
      updated_at: updatedAt,
      media_set_id: this.presentationMediaSetId,
      video_aspect_ratio: this.videoAspectRatio,
      video_ratio: this.videoRatio,
      display_video_ratio: this.displayVideoRatio,
      streaming: {
        details: {
          finished: this.streamFinished,
          available_on_demand: this.streamAvailableOnDemand,
          terminated: this.streamTerminated,
          servers: this.streamVideoServers,
          delay: this.streamSyncDelay,
          subtitles_enabled: this.streamSubtitlesEnabled,
          subtitles_delay_ms: this.streamSubtitlesDelay,
          video_actual_start: this.streamVideoActualStart,
          video_scheduled_start: this.streamVideoScheduledStart,
          show_slides: this.streamShowSlides,
          update_interval: this.streamUpdateInterval,
          reload_counter: this.streamReloadCounter,
          slides_video: this.streamSlidesVideo,
        },
        slides: {
          slides: streamSlides,
        },
      },
      slideslive_logo_visible: this.slidesliveLogoVisible,
      slideslive_logo_linkify: this.slidesliveLogoLinkify,
    };
  }
}

export default class PresentationPlaylistLoader {
  constructor(options) {
    this.options = options;

    this.props = {
      request: null,
      playlistUrl: null,

      destroyed: false,
    };

    this.playlist = null;
  }

  checkBot(callbacks) {
    if (isBotUserAgent()) {
      console.warn('PLST', 'You are a search engine bot.');
      callbacks.fatalError(botErrorMessage());

      return true;
    }

    return false;
  }

  loadPresentationInfo(callbacks) {
    if (this.props.destroyed) {
      return;
    }

    if (this.checkBot(callbacks)) {
      return;
    }

    if (this.props.request) {
      return;
    }

    if (this.props.playlistUrl) {
      this.loadPresentationInfoFromPlaylistUrl(callbacks);
    } else {
      this.loadPresentationInfoFromSlidesLive(callbacks);
    }
  }

  loadPresentationInfoFromPlaylistUrl(callbacks) {
    this.loadPlaylistFromPlaylistUrl(callbacks);
  }

  loadPresentationInfoFromSlidesLive(callbacks) {
    const query = [];

    if (isVariableDefinedNotNull(this.options.playerToken)) {
      query.push(`player_token=${this.options.playerToken}`);
    }

    if (isVariableDefinedNotNull(this.options.registrationId)) {
      query.push(`registration_id=${this.options.registrationId}`);
    }

    if (isVariableDefinedNotNull(this.options.reviewToken)) {
      query.push(`review_token=${this.options.reviewToken}`);
    }

    if (isVariableDefinedNotNull(this.options.yodaPreferredCdnLabelOverride)) {
      query.push(`force_video_cdn_label=${this.options.yodaPreferredCdnLabelOverride}`);
    }

    if (isVariableDefinedNotNull(this.options.yodaCountryOverride)) {
      query.push(`country_override=${this.options.yodaCountryOverride}`);
    }

    this.sendRequest(`${this.options.benBaseUrl}/player/${this.options.presentationId}?${query.sort().join('&')}`, {
      errorCallback: ({ error = undefined, response = undefined }) => {
        console.warn('PLST', 'Loading presentation info failed.', error, response);

        if (!response) {
          callbacks.retriableError(unknownErrorMessage());
          return;
        }

        response
          .json()
          .catch(() => callbacks.retriableError(unknownErrorMessage()))
          .then((json) => {
            if (!json) {
              return;
            }

            if (!json.success && json.code) {
              callbacks.fatalError(errorCodeToMessage(response.code));
            }

            callbacks.retriableError(unknownErrorMessage());
          });
      },
      successCallback: (responseText) => {
        if (!responseText) {
          if (callbacks.success) callbacks.success();
          return;
        }

        this.processPlaylistFromSlidesLive(responseText, callbacks);
      },
    });
  }

  processPlaylistFromSlidesLive(playlist, callbacks) {
    if (playlist.startsWith('#')) {
      if (callbacks.success) callbacks.success();
      this.parsePlaylist(playlist, callbacks);

      return;
    }

    if (playlist.startsWith('{')) {
      const json = JSON.parse(playlist);

      if (json.playlist) {
        if (callbacks.success) callbacks.success();
        this.parsePlaylist(json.playlist, callbacks);
      } else if (json.playlist_url) {
        this.props.playlistUrl = json.playlist_url;
        this.loadPlaylistFromPlaylistUrl(callbacks);
      } else {
        console.warn('PLST', 'Loading presentation playlist failed. Invalid JSON content.');

        callbacks.retriableError('Invalid playlist response. Try again.');
      }

      return;
    }

    console.warn('PLST', 'Loading presentation playlist failed. Invalid content.');

    callbacks.retriableError('Invalid playlist response. Try again.');
  }

  loadPlaylistFromPlaylistUrl(callbacks) {
    this.sendRequest(this.props.playlistUrl, {
      errorCallback: ({ error = undefined, response = undefined }) => {
        console.warn('PLST', 'Loading presentation playlist from URL failed. Network error.', error, response);
        callbacks.retriableError('Presentation playlist loading failed. Try again.');

        this.props.playlistUrl = null;
      },
      successCallback: (responseText) => {
        if (!responseText) {
          if (callbacks.success) callbacks.success();
          return;
        }

        if (responseText.startsWith('#')) {
          if (callbacks.success) callbacks.success();
          this.parsePlaylist(responseText, callbacks);
        } else {
          callbacks.retriableError('Invalid playlist format. Try again.');
        }
      },
    });
  }

  sendRequest(url, { successCallback, errorCallback }) {
    this.props.request = fetch(`${url}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'strict-origin-when-cross-origin',
    })
      .catch((error) => {
        this.props.request = null;

        if (this.props.destroyed) {
          return;
        }

        errorCallback({ error });
      })
      .then((response) => {
        if (!response && !this.props.request) {
          return;
        }

        this.props.request = null;

        if (this.props.destroyed) {
          return;
        }

        if (response.status === 304) {
          successCallback();
          return;
        }

        if (response.status < 200 || response.status >= 400) {
          errorCallback({ response });
          return;
        }

        response
          .text()
          .catch((error) => errorCallback({ error }))
          .then((text) => successCallback(text));
      });

    return this.props.request;
  }

  parsePlaylist(playlist, callbacks) {
    const now = window.performance.now();
    const newPlaylist = this.doParsePlaylist(playlist);
    const duration = window.performance.now() - now;

    if (duration > 20000) {
      console.warn('PLST', `Parsing presentation playlist took ${duration} ms.`);
    }

    if (newPlaylist) {
      this.playlist = newPlaylist;

      if (callbacks.playlist) {
        callbacks.playlist(this.playlist);
      }

      if (callbacks.presentationInfo) {
        callbacks.presentationInfo(this.playlist.toDeprecatedPresentationInfo());
      }
    } else {
      callbacks.retriableError('Presentation playlist parsing failed. Try again.');
    }
  }

  doParsePlaylist(playlist) {
    if (!playlist.startsWith('#EXTM3U\n')) {
      console.warn('PLST', 'Presentation playlist parsing failed.');
      return null;
    }

    const newPlaylist = new Playlist();

    let lineStart = 0;
    let lineEnd;
    let line;

    while (lineStart < playlist.length) {
      lineEnd = playlist.indexOf('\n', lineStart);
      if (lineEnd < 0) lineEnd = playlist.length;

      line = playlist.substring(lineStart, lineEnd);

      // eslint-disable-next-line no-continue
      if (line === '#EXTM3U') {
        lineStart = lineEnd + 1;
        continue;
      }

      // eslint-disable-next-line no-continue
      if (line.size === 0) {
        lineStart = lineEnd + 1;
        continue;
      }

      // eslint-disable-next-line no-continue
      if (line[0] !== '#') {
        lineStart = lineEnd + 1;
        continue;
      }

      const colonIndex = line.indexOf(':');
      if (colonIndex < 0) {
        const tag = line.substring(1);

        console.warn(`Unhandled tag ${tag}.`);
      } else {
        const tag = line.substring(1, colonIndex);
        const value = line.substring(colonIndex + 1);

        switch (tag) {
          case 'EXT-SL-VERSION':
          case 'EXT-SL-ACCOUNT-ID':
          case 'EXT-SL-PRESENTATION-ID':
          case 'EXT-SL-PRESENTATION-MEDIA-SET-ID':
          case 'EXT-SL-START-TIME':
          case 'EXT-SL-NEXT-PRESENTATION-ID':
          case 'EXT-SL-STREAM-SYNC-DELAY':
          case 'EXT-SL-STREAM-SUBTITLES-DELAY':
          case 'EXT-SL-STREAM-UPDATE-INTERVAL':
          case 'EXT-SL-STREAM-RELOAD-COUNTER':
            newPlaylist._storePlaylistIntegerValue(tag, value);
            break;

          case 'EXT-SL-VIDEO-RATIO':
          case 'EXT-SL-DISPLAY-VIDEO-RATIO':
          case 'EXT-SL-VIDEO-ASPECT-RATIO':
            newPlaylist._storePlaylistFloatValue(tag, value);
            break;

          case 'EXT-SL-PLAYLIST-TYPE':
          case 'EXT-SL-PLAYLIST-CREATED-AT':
          case 'EXT-SL-PRESENTATION-TITLE':
          case 'EXT-SL-PRESENTATION-THUMBNAIL':
          case 'EXT-SL-DEFAULT-SUBTITLES-LANGUAGE':
          case 'EXT-SL-PLAYER-LOGO':
          case 'EXT-SL-NEXT-PRESENTATION-TITLE':
          case 'EXT-SL-NEXT-PRESENTATION-THUMBNAIL':
          case 'EXT-SL-PLAYER-TYPE':
          case 'EXT-SL-VOD-VIDEO-SERVICE-NAME':
          case 'EXT-SL-VOD-VIDEO-ID':
          case 'EXT-SL-VOD-SLIDES-XML-URL':
          case 'EXT-SL-VOD-SLIDES-JSON-URL':
          case 'EXT-SL-PRESENTATION-UPDATED-AT':
          case 'EXT-SL-STREAM-UPDATED-AT':
          case 'EXT-SL-STREAM-SLIDES-UPDATED-AT':
            newPlaylist._storePlaylistStringValue(tag, value);
            break;

          case 'EXT-SL-STREAM-VIDEO-ACTUAL-START':
          case 'EXT-SL-STREAM-VIDEO-SCHEDULED-START':
            newPlaylist._storePlaylistDateTimeValue(tag, value);
            break;

          case 'EXT-SL-CUSTOM-CMCD-TRACKING':
          case 'EXT-SL-SLIDESLIVE-LOGO-VISIBLE':
          case 'EXT-SL-SLIDESLIVE-LOGO-LINKIFY':
          case 'EXT-SL-PLAYING-ANALYTICS-DISABLED':
          case 'EXT-SL-HAS-VIDEO':
          case 'EXT-SL-HAS-AUDIO':
          case 'EXT-SL-HAS-SLIDES':
          case 'EXT-SL-VOD-VIDEO-KEN-ENABLED':
          case 'EXT-SL-STREAM-FINISHED':
          case 'EXT-SL-STREAM-AVAILABLE-ON-DEMAND':
          case 'EXT-SL-STREAM-TERMINATED':
          case 'EXT-SL-STREAM-SHOW-SLIDES':
          case 'EXT-SL-STREAM-SUBTITLES-ENABLED':
          case 'EXT-SL-STREAM-SLIDES-VIDEO':
            newPlaylist._storePlaylistBooleanValue(tag, value);
            break;

          case 'EXT-SL-STREAM-VIDEO-SERVERS':
          case 'EXT-SL-VOD-VIDEO-SERVERS':
          case 'EXT-SL-VOD-SUBTITLES':
            newPlaylist._storePlaylistJsonValue(tag, value);
            break;

          case 'EXT-SL-STREAM-SLIDE':
            if (playlist[lineEnd + 1] !== '#') {
              lineStart = lineEnd + 1;
              lineEnd = playlist.indexOf('\n', lineStart);
              if (lineEnd < 0) lineEnd = playlist.length;

              line = playlist.substring(lineStart, lineEnd);
            } else {
              line = null;
            }

            newPlaylist._storePlaylistStreamSlide(tag, value, line);
            break;

          default:
            console.warn(`Unhandled presentation playlist tag ${tag}:`, value);
        }
      }

      lineStart = lineEnd + 1;
    }

    return newPlaylist;
  }

  destroy() {
    this.props.destroyed = true;
  }
}
